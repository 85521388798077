import React from "react";
import Lottie from "lottie-react-web";
import loading from "assets/json/loading.json";
import Container from "./styles";

const Loading = () => (
  <Container>
    <Lottie options={{ animationData: loading, loop: true }} />
  </Container>
);

export default Loading;
