import styled from "styled-components";
import { GREEN } from "config/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${GREEN};
  padding: 20px 0px;
  width: 100vw;
`;

export const Logo = styled.img``;
