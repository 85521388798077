export default {
  translation: {
    "form-question": (application) => `¿Cómo calificaría el ${application}?`,
    "form-tell-us": "Cuéntanos un poco más de tu experiencia...",
    "form-tell-us-subtitle":
      "Utilice este espacio para informar problemas, sugerir mejoras y resaltar puntos positivos.",
    "type-here": "escriba aquí...",
    send: "Guardar",
    "success-title": "Todo cierto",
    "success-subtitle":
      "Sus comentarios se han enviado correctamente. Agradecemos su participación.",
    "error-message":
      "No pudimos enviar sus comentarios en este momento. Vuelva a intentarlo más tarde.",
  },
};
