import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  #gatsby-focus-wrapper,
  html,
  body {
    margin: 0;
    height: 100vh;
    width: 100vw;
    font-family: Helvetica Neue;
  }
`;
