import styled from "styled-components";
import { ORANGE, LIGHT_GREY_2 } from "config/colors";

export default styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-radius: 4px;
  width: 100%;
  border: none;
  background-color: ${({ disabled }) => (disabled ? LIGHT_GREY_2 : ORANGE)};
  padding: 12px 0px;
  box-shadow: 0px 15px 55px rgba(157, 163, 180, 0.16);
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  margin-right: ${({ marginRight }) => marginRight}px;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.1s ease-in-out;
  height: 48px;

  &:focus {
    outline: none;
  }
`;
