import styled from "styled-components";

export default styled.div`
  font-family: Helvetica Neue;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  margin-left: ${({ marginLeft }) => marginLeft}px;
  margin-right: ${({ marginRight }) => marginRight}px;
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  @media screen and (max-width: 360px) and (height: 568px) {
    font-size: ${({ fontSize }) => Math.ceil(fontSize * 0.7)}px;
  }
`;
