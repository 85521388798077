import React from "react";
import PropTypes from "prop-types";
import { BLACK } from "config/colors";
import Container from "./styles";

const Label = ({
  children,
  fontSize,
  fontWeight,
  color,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
}) => (
  <Container
    fontSize={fontSize}
    fontWeight={fontWeight}
    color={color}
    marginLeft={marginLeft}
    marginRight={marginRight}
    marginTop={marginTop}
    marginBottom={marginBottom}
  >
    {children}
  </Container>
);

Label.propTypes = {
  children: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

Label.defaultProps = {
  fontSize: 16,
  fontWeight: "normal",
  color: BLACK,
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,
};

export default Label;
