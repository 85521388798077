export default {
  translation: {
    "form-question": (application) => `How would you rate the ${application}?`,
    "form-tell-us": "Tell us a little bit about your experience...",
    "form-tell-us-subtitle":
      "Use this space to report problems, suggest improvements and highlight positive points.",
    "type-here": "Type here...",
    send: "Save",
    "success-title": "All right!",
    "success-subtitle": "Your feedback was sent successfully. We appreciate your participation.",
    "error-message": "We were unable to send your feedback at this time, please try again later.",
  },
};
