import React, { useState } from "react";
import PropTypes from "prop-types";
import { WHITE } from "config/colors";
import Label from "../Label";
import Loading from "../Loading";
import Container from "./styles";

const Button = ({
  action,
  label,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  type,
  disabled,
  isLoading,
}) => {
  const [opacity, setOpacity] = useState(1);
  return (
    <Container
      onClick={() => {
        setOpacity(0.5);
        setTimeout(() => setOpacity(1), 100);
        action();
      }}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      opacity={opacity}
      type={type}
      disabled={disabled}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Label fontWeight="500" color={WHITE}>
          {label}
        </Label>
      )}
    </Container>
  );
};

Button.propTypes = {
  action: PropTypes.func,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  action: () => {},
  type: "button",
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  disabled: false,
  isLoading: false,
};

export default Button;
