import React from "react";
import logo from "assets/images/logo.svg";
import * as S from "./styles";

const Header = () => (
  <S.Container>
    <S.Logo src={logo} />
  </S.Container>
);

export default Header;
