import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import pt from "./locales/pt";
import en from "./locales/en";
import es from "./locales/es";

i18next.use(initReactI18next).init({
  lng: "pt",
  fallbackLng: "pt",
  resources: {
    pt,
    en,
    es,
  },
});

export const changeLanguage = (lng) => {
  i18next.changeLanguage(lng);
};

export default i18next;
