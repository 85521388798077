export default {
  translation: {
    "form-question": (application) => `Como você classificaria o ${application}?`,
    "form-tell-us": "Conte-nos um pouco mais sobre sua experiência…",
    "form-tell-us-subtitle":
      "Use esse espaço para reportar problemas, sugerir melhorias e ressaltar pontos positivos.",
    "type-here": "Digite aqui...",
    send: "Enviar",
    "success-title": "Tudo certo!",
    "success-subtitle": "Seu feedback foi enviado com sucesso. Agradecemos sua participação.",
    "error-message":
      "Não foi possível enviar seu feedback no momento, por favor tente novamente mais tarde.",
  },
};
